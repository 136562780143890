import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, CheckCircle, Code, BookOpen, Users, Mail, Phone, MapPin,  Zap, Globe } from 'lucide-react';

const testimonials = [
    { name: "Sarah Johnson", role: "CTO, TechCorp", text: "PentaCode's expertise in Spring and Jakarta EE transformed our legacy system into a modern, scalable platform.", image: "/testimonial1.jpg" },
    { name: "Ahmed Al-Mansoori", role: "Senior Developer, InnovateNow", text: "The Quarkus training from PentaCode was instrumental in our successful cloud migration project.", image: "/testimonial2.jpg" },
    { name: "Lisa Chen", role: "Team Lead, GlobalSoft", text: "PentaCode's custom software development helped us launch our product months ahead of schedule.", image: "/testimonial3.jpg" },
];

const services = [
    { title: "Enterprise Java Solutions", icon: <Code size={24} />, description: "Tailored Spring, Quarkus, and Jakarta EE applications with seamless AI integration" },
    { title: "AI-Enhanced Java Development", icon: <BookOpen size={24} />, description: "Leveraging artificial intelligence to create smarter, more efficient enterprise solutions" },
    { title: "Specialized Java Training", icon: <Users size={24} />, description: "Customized online and on-premise workshops for Spring, Quarkus, and Jakarta EE mastery" },
];

const FloatingActionButton = () => (
    <motion.a
        href="#contact"
        className="fixed bottom-8 right-8 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
    >
        <Mail size={24} />
    </motion.a>
);

// Add this component to the main return statement, just before the closing </div>
export default function Home() {
    const [activeTestimonial, setActiveTestimonial] = useState(0);
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsHeaderSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
            <header className={`bg-white shadow-md transition-all duration-300 ${isHeaderSticky ? 'fixed top-0 left-0 right-0 z-50' : ''}`}>
                <nav className="container mx-auto px-6 py-3">
                    <div className="flex justify-between items-center">
                        <a href="/" className="flex items-center">
                            <img
                                src="/logo-text-color.png"
                                alt="PentaCode Studios"
                                className="h-10 w-auto"
                            />
                        </a>
                        <div className="hidden md:flex space-x-6">
                            <a href="#services" className="text-gray-700 hover:text-blue-600">Services</a>
                            <a href="#about" className="text-gray-700 hover:text-blue-600">About Us</a>
                            <a href="#testimonials" className="text-gray-700 hover:text-blue-600">Testimonials</a>
                            <a href="#contact" className="text-gray-700 hover:text-blue-600">Contact</a>
                        </div>
                        <a href="#contact"
                           className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">Get
                            Started</a>
                    </div>
                </nav>
            </header>

            <main className={isHeaderSticky ? 'pt-16' : ''}>
                <section className="relative py-20 overflow-hidden">
                    <div className="container mx-auto px-6 relative z-10">
                        <div className="md:flex items-center">
                            <motion.div
                                initial={{opacity: 0, x: -50}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.8}}
                                className="md:w-1/2 mb-8 md:mb-0"
                            >
                                <h1 className="text-5xl font-bold text-gray-800 mb-4">Innovative Enterprise Java Solutions with AI Integration</h1>
                                <p className="text-xl text-gray-600 mb-8">Propelling UAE, EU, and UK businesses forward with cutting-edge Spring, Quarkus, and Jakarta EE expertise</p>
                                <motion.a
                                    href="#contact"
                                    whileHover={{scale: 1.05}}
                                    whileTap={{scale: 0.95}}
                                    className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg hover:bg-blue-700 transition duration-300 inline-flex items-center"
                                >
                                    Transform Your Enterprise <ArrowRight className="ml-2"/>
                                </motion.a>
                            </motion.div>
                            <motion.div
                                initial={{opacity: 0, x: 50}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.8, delay: 0.2}}
                                className="md:w-1/2"
                            >
                                <img src="/hero-image.jpg" alt="Java Development" className="rounded-lg shadow-2xl"/>
                            </motion.div>
                        </div>
                    </div>
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-blue-100 transform skew-x-12 -z-10"></div>
                </section>

                <section id="services" className="py-20 bg-white">
                    <div className="container mx-auto px-6">
                        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-12">Our Services</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            {services.map((service, index) => (
                                <motion.div
                                    key={index}
                                    className="bg-gradient-to-br from-white to-blue-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 group"
                                    whileHover={{scale: 1.05, y: -10}}
                                    whileTap={{scale: 0.95}}
                                >
                                    <motion.div
                                        className="text-blue-600 mb-4 group-hover:text-blue-800 transition-colors duration-300"
                                        whileHover={{rotate: 360, transition: {duration: 0.6}}}
                                    >
                                        {service.icon}
                                    </motion.div>
                                    <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors duration-300">{service.title}</h3>
                                    <p className="text-gray-600 group-hover:text-gray-800 transition-colors duration-300">{service.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                <section id="about" className="py-20 bg-gray-50">
                    <div className="container mx-auto px-6">
                        <div className="md:flex items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <h2 className="text-3xl font-semibold text-gray-800 mb-4">PentaCode Studios: Your Java AI Powerhouse
                                    </h2>
                                <p className="text-gray-600 mb-4">
                                    Based in the UAE, PentaCode Studios is a premier software consulting firm specializing in enterprise Java applications enhanced by AI.
                                    Our mastery of Spring, Jakarta EE, and Quarkus, combined with cutting-edge AI integration, enables us to deliver reliable, scalable, and intelligent solutions for businesses across the UAE, EU, and UK.
                                </p>
                                <ul className="text-gray-600 mb-6">
                                    <li className="flex items-center mb-2"><CheckCircle className="text-green-500 mr-2"
                                                                                        size={20}/> 5+ years of industry
                                        experience
                                    </li>
                                    <li className="flex items-center mb-2"><CheckCircle className="text-green-500 mr-2"
                                                                                        size={20}/> Certified Java and
                                        cloud experts
                                    </li>
                                    <li className="flex items-center"><CheckCircle className="text-green-500 mr-2"
                                                                                   size={20}/> Customized training
                                        programs
                                    </li>
                                </ul>
                                <div
                                    className="inline-block bg-blue-50 rounded-lg shadow-md border border-blue-200 p-3 transition-all duration-300 hover:shadow-lg hover:scale-105">
                                    <div className="flex items-center space-x-2">
                                        <Globe className="text-blue-600" size={24}/>
                                        <p className="text-blue-700 font-semibold">
                                            Serving clients across the UAE, EU, and UK
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 md:pl-8">
                                <div className="relative">
                                    <img src="/team-photo.png" alt="PentaCode Team"
                                         className="rounded-lg shadow-2xl w-full"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="py-20 bg-gradient-to-br from-blue-500 to-indigo-600 text-white">
                    <div className="container mx-auto px-6">
                        <h2 className="text-3xl font-semibold text-center mb-12">What Our Clients Say</h2>
                        <div className="max-w-4xl mx-auto">
                            <motion.div
                                key={activeTestimonial}
                                initial={{opacity: 0, y: 50}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -50}}
                                transition={{duration: 0.5}}
                                className="bg-white text-gray-800 p-8 rounded-lg shadow-xl relative"
                            >
                                <div className="md:flex items-center">
                                    <div className="md:w-1/3 mb-4 md:mb-0">
                                        <motion.img
                                            src={testimonials[activeTestimonial].image}
                                            alt={testimonials[activeTestimonial].name}
                                            className="rounded-full w-32 h-32 mx-auto border-4 border-blue-500"
                                            initial={{scale: 0.8}}
                                            animate={{scale: 1}}
                                            transition={{duration: 0.5}}
                                        />
                                    </div>
                                    <div className="md:w-2/3 md:pl-8">
                                        <p className="text-gray-600 italic mb-4 text-lg">"{testimonials[activeTestimonial].text}"</p>
                                        <p className="font-semibold text-blue-600">{testimonials[activeTestimonial].name}</p>
                                        <p className="text-sm text-gray-500">{testimonials[activeTestimonial].role}</p>
                                    </div>
                                </div>
                                <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                                    <div className="flex space-x-2">
                                        {testimonials.map((_, index) => (
                                            <button
                                                key={index}
                                                className={`w-3 h-3 rounded-full ${index === activeTestimonial ? 'bg-blue-600' : 'bg-gray-300'}`}
                                                onClick={() => setActiveTestimonial(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </section>

                <section id="contact" className="py-20 bg-white">
                    <div className="container mx-auto px-6">
                        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-12">Elevate Your Enterprise Java Projects <br/>
                            Schedule a Free Consultation with One of Our Experts Now</h3>
                        <div className="max-w-4xl mx-auto md:flex items-start">
                            <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                                <img src="/contact-image.jpg" alt="Contact Us" className="rounded-lg shadow-lg"/>
                                <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                                    <h3 className="text-xl font-semibold mb-4">Why Choose Us?</h3>
                                    <ul className="space-y-2">
                                        <li className="flex items-center"><Zap className="text-yellow-500 mr-2"
                                                                               size={20}/> Cutting-edge Java solutions
                                        </li>
                                        <li className="flex items-center"><Zap className="text-yellow-500 mr-2"
                                                                               size={20}/> Expert-led training programs
                                        </li>
                                        <li className="flex items-center"><Zap className="text-yellow-500 mr-2"
                                                                               size={20}/> Tailored approach for each
                                            client
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="md:w-1/2">
                                <form className="bg-white shadow-lg rounded-lg p-8">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="name">Name</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition duration-300"
                                            id="name" type="text" placeholder="Your Name"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="email">Email</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition duration-300"
                                            id="email" type="email" placeholder="your@email.com"
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="message">Message</label>
                                        <textarea
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition duration-300"
                                            id="message" rows="4" placeholder="How can we help you?"
                                        ></textarea>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <motion.button
                                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                                            type="submit"
                                            whileHover={{scale: 1.05}}
                                            whileTap={{scale: 0.95}}
                                        >
                                            Send Message
                                        </motion.button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-800 text-white py-12">
                <div className="container mx-auto px-6">
                    <div className="md:flex justify-between items-center">
                        <div className="mb-6 md:mb-0">
                            <a href="/" className="inline-block">
                                <img
                                    src="/logo-text-color.png"
                                    alt="PentaCode Studios"
                                    className="h-10 w-auto mb-2"
                                />
                            </a>
                            <p className="mt-2">Pioneering Java excellence: UAE-rooted, globally connected</p>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-8">
                            <div className="flex items-center mb-4 md:mb-0">
                                <Mail size={20} className="mr-2"/>
                                <a href="mailto:hello@pentacodestudios.com">hello@pentacodestudios.com</a>
                            </div>
                            <div className="flex items-center mb-4 md:mb-0">
                                <Phone size={20} className="mr-2"/>
                                <a href="tel:+97123456789">+971 526221679</a>
                            </div>
                            <div className="flex items-center">
                                <MapPin size={20} className="mr-2"/>

                                <span>
                                   <p>FDRK0427 Compass Building</p>
                               <p> Al Shohada Road</p>
                                <p>AL Hamra Industrial Zone-FZ</p>
                              <p>  Ras Al Khaimah</p>
                        <p>Dubai, UAE</p>

                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 text-center text-gray-400 text-sm">
                        © 2024 PentaCode Studios. All rights reserved.
                    </div>
                </div>
            </footer>

            <FloatingActionButton/>

        </div>
    );
}