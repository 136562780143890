import React from 'react';
import PentaCodeLanding from './PentaCodeLanding';

function App() {
  return (
      <div className="App">
        <PentaCodeLanding />
      </div>
  );
}

export default App;